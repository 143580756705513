import { gql } from "@apollo/client";

export const DELETE_BUDGET_ITEM = gql`
  mutation deleteBudgetItem($bdgtLnItmId: ID!, $pId: ID!, $cId: ID!) {
    DeleteBudgetItem(bdgtLnItmId: $bdgtLnItmId, pId: $pId, cId: $cId) {
      message
      success
      result
    }
  }
`;
