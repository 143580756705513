import styled from "styled-components";
import { Color } from "../../styles/Colors";

export const UserFields = styled.form<{ isEdit: boolean }>`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  padding: ${({ isEdit }) => (isEdit ? "none" : "24px")};
  margin: ${({ isEdit }) => (isEdit ? "none" : "32px")};
  background: ${({ isEdit }) => (isEdit ? "none" : Color.white)};
  border: ${({ isEdit }) => (isEdit ? "none" : `1px solid ${Color.gray}`)};
  border-radius: ${({ isEdit }) => (isEdit ? "0" : "5px")};

  > * {
    flex: 1 1 calc(25% - 16px);
    box-sizing: border-box;
  }

  [name="Site Superintendent"] {
    flex: 1 1 calc(25% - 16px);
    box-sizing: border-box;
  }

  .textarea-wrapper {
    display: flex;
    gap: 16px;
    width: 100%;
  }

  .textarea-wrapper > * {
    flex: 1;
    min-height: 100px;
    box-sizing: border-box;
  }
`;
