// @ts-nocheck
import React, { useState, useEffect } from "react";
import { UnitContainer } from "./UnitInformation.styles";
import { PrimeReactProvider } from "primereact/api";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import EditIcon from "../../assets/images/Common/EditIcon.png";
import DeleteIcon from "../../assets/images/Common/deleteIcon.png";

import { Button } from "../Button/Button";
import { Toolbar } from "primereact/toolbar";
import {
  BudgetInfo,
  CustomDialogFooter,
  ActionButton,
} from "./UnitInformation.styles";
import { formatUnits } from "../../utils";
import UnitInformationModal from "./UnitInformationModal/UnitInformationModal";
import Modal from "../Modal/Modal";
import { unit } from "./constants";
import { getUnits, createUnit, updateUnit, deleteUnit } from "../../redux";
import { formatUnitData, mapCurrentUnitFields } from "./utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { StyledToast } from "../CreateNewUser/CreateNewUser.styles";
import EmptyTable from "./EmptyTable/EmptyTable";
import { TableConatiner } from "../BudgetList/BudgetList.styles";
import useReadOnlyUser from "../../hooks/useReadOnlyUser";
import { Color } from "../../styles/Colors";

export const UnitInformation = ({ pId, cId, pName }) => {
  const dispatch = useDispatch();
  const [inputValues, setInputValues] = React.useState({ ...unit });
  const [unitItems, setUnitItems] = useState(null);
  const [unitDialog, setUnitDialog] = useState(false);
  const [deleteUnitDialog, setDeleteUnitDialog] = useState(false);
  const [unitItem, setUnit] = useState(null);
  const [isEdit, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const isReadonlyUser = useReadOnlyUser();

  const columnStyle = {
    padding: "14px 16px",
    border: "none",
    borderBottom: `1px solid ${Color.gray}`,
  };

  const getAllUnits = () => {
    setIsLoading(true);
    dispatch(
      getUnits({ pId, cId }, (items) => {
        const modifiedUnits = formatUnits(items);
        setUnitItems(modifiedUnits);
        setIsLoading(false);
      })
    );
  };

  useEffect(() => {
    getAllUnits();
  }, []);

  const openNew = () => {
    setInputValues({ ...unit });
    setUnit(null);
    setUnitDialog(true);
  };

  const hideDialog = () => {
    setEditMode(false);
    setUnitDialog(false);
    setUnit(null);
  };

  const hideDeleteUnitDialog = () => {
    setDeleteUnitDialog(false);
  };

  const editUnit = (unitItem) => {
    setUnit(unitItem);
    setUnitDialog(true);
    setEditMode(true);
    const modifiedInputValue = mapCurrentUnitFields(inputValues, unitItem);
    setInputValues(modifiedInputValue);
  };

  const confirmDeleteUnit = (unitItem) => {
    setUnit(unitItem);
    setDeleteUnitDialog(true);
  };

  const deleteUnitItem = () => {
    const { unitId } = unitItem || {};
    setIsLoading(true);
    setIsBtnLoading(true);

    dispatch(
      deleteUnit({ unitId, pId, cId }, (res) => {
        if (res?.success) {
          getAllUnits();
          notifySuccess("Unit deleted successfully!");
        } else {
          notifyError("Not able to delete unit!");
          setIsLoading(false);
        }
        setIsBtnLoading(false);
        setDeleteUnitDialog(false);
      })
    );
  };

  const sum =
    unitItems !== null &&
    unitItems.reduce((acc, obj) => acc + Number(obj.sqFt), 0);

  const leftToolbarTemplate = () => {
    return (
      <BudgetInfo>
        <span>Total Square Feet: {sum > 0 ? sum : 0}</span>|
        <span>
          Total Units:{" "}
          {unitItems !== null && unitItems.length > 0 ? unitItems.length : 0}
        </span>
      </BudgetInfo>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <Button
        type="button"
        onClick={openNew}
        hasStartIcon={true}
        className="btn-primary"
        disabled={isReadonlyUser}
      >
        Create Unit
      </Button>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <ActionButton>
        <Button
          type="button"
          onClick={() => editUnit(rowData)}
          className="btn-default"
          disabled={isReadonlyUser}
        >
          <img src={EditIcon} alt="editIcon" />
        </Button>
        <Button
          type="button"
          onClick={() => confirmDeleteUnit(rowData)}
          className="btn-default"
          disabled={isReadonlyUser}
        >
          <img src={DeleteIcon} alt="deleteIcon" />
        </Button>
      </ActionButton>
    );
  };

  const deleteUnitDialogFooter = (
    <CustomDialogFooter>
      <Button
        type="button"
        className="btn-default"
        onClick={hideDeleteUnitDialog}
      >
        Cancel
      </Button>
      <Button
        type="button"
        className="btn-primary"
        onClick={deleteUnitItem}
        isLoading={isBtnLoading}
      >
        Delete
      </Button>
    </CustomDialogFooter>
  );

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const notifySuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const saveUnit = () => {
    const input = formatUnitData(inputValues);
    setIsLoading(true);
    setIsBtnLoading(true);

    if (isEdit) {
      dispatch(
        updateUnit({ input, pId, cId }, (res) => {
          if (res?.success) {
            notifySuccess(res?.message);
            getAllUnits();
          } else {
            notifyError(res?.message || "Not able to update unit");
            setIsLoading(false);
          }
          setIsBtnLoading(false);
          hideDialog();
        })
      );
    } else {
      dispatch(
        createUnit({ input, pId, cId }, (res) => {
          if (res?.success) {
            notifySuccess(res?.message);
            getAllUnits();
          } else {
            notifyError(res?.message || "Not able to create unit");
            setIsLoading(false);
          }
          setIsBtnLoading(false);
          hideDialog();
        })
      );
    }
  };

  return (
    <UnitContainer>
      <PrimeReactProvider>
        <div>
          <StyledToast />
          <div className="card">
            <Toolbar
              style={{ backgroundColor: "unset", border: "none" }}
              className="mb-4"
              left={leftToolbarTemplate}
              right={rightToolbarTemplate}
            ></Toolbar>
            <TableConatiner>
              <DataTable
                value={isLoading ? [] : unitItems}
                showGridlines
                scrollable
                scrollHeight="400px"
                size="small"
                tableStyle={{
                  minWidth: "50rem",
                  border: `1px solid ${Color.gray}`,
                }}
                sortField={"unitName"}
                sortOrder={1}
                loading={isLoading}
                emptyMessage={<EmptyTable tableName={"Unit"} />}
              >
                <Column
                  hidden
                  style={columnStyle}
                  sortable
                  field="unitId"
                  header="Unit"
                ></Column>
                <Column
                  style={columnStyle}
                  sortable
                  field="unitName"
                  header="Unit"
                ></Column>
                <Column
                  style={columnStyle}
                  field="stories"
                  header="Stories"
                ></Column>
                <Column
                  style={columnStyle}
                  field="sqFt"
                  header="Sq Ft"
                ></Column>
                <Column style={columnStyle} field="beds" header="Beds"></Column>
                <Column
                  style={columnStyle}
                  field="baths"
                  header="Baths"
                ></Column>
                <Column style={columnStyle} field="gar" header="Gar"></Column>
                <Column
                  style={columnStyle}
                  field="apprVal"
                  header="Appr. Val"
                ></Column>
                <Column
                  style={columnStyle}
                  field="intVal"
                  header="Int. Val"
                ></Column>
                <Column
                  style={columnStyle}
                  field="mlsListingDt"
                  header="List Date"
                ></Column>
                <Column
                  style={columnStyle}
                  field="mlsPendingDt"
                  header="Pending Date"
                ></Column>
                <Column
                  style={columnStyle}
                  field="closingDt"
                  header="Closing Date"
                ></Column>
                <Column
                  style={{ ...columnStyle, minWidth: "12rem" }}
                  field="actions"
                  header="Actions"
                  body={actionBodyTemplate}
                  exportable={false}
                  alignFrozen="right"
                  frozen
                ></Column>
              </DataTable>
            </TableConatiner>
          </div>

          {unitDialog && (
            <UnitInformationModal
              isEdit={isEdit}
              isOpen={unitDialog}
              onClose={hideDialog}
              unitItem={unitItem}
              saveUnit={saveUnit}
              inputValues={inputValues}
              setInputValues={setInputValues}
              pName={pName}
              isBtnLoading={isBtnLoading}
            />
          )}

          {deleteUnitDialog && (
            <Modal
              open={deleteUnitDialog}
              header="Delete Unit?"
              footer={deleteUnitDialogFooter}
              onClose={hideDeleteUnitDialog}
            >
              <p>
                Are you sure you want to delete
                {unitItem.name && <b>{unitItem.name}</b>}?
              </p>
            </Modal>
          )}
        </div>
      </PrimeReactProvider>
    </UnitContainer>
  );
};

export default UnitInformation;
