import { graphQLFetch } from "../../../utils";
// import { SET_DELETE_BUDGET_ITEM } from "../../../constants";
import { DELETE_BUDGET_ITEM } from "../../queries";

export const deleteBudgetItem =
  ({ bdgtLnItmId, pId, cId }, callback) =>
  async (dispatch, getState) => {
    const variables = {
      bdgtLnItmId,
      pId,
      cId
    };
    const data = await graphQLFetch(
      DELETE_BUDGET_ITEM,
      variables,
      "mutation",
      getState
    );
    const { DeleteBudgetItem } = data || {};
    callback(DeleteBudgetItem);

    return DeleteBudgetItem;
  };
