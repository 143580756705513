import { gql } from "@apollo/client";

export const GET_INSPECTIONS = gql`
  query getProjInsp($pId: ID!, $cId: ID!) {
    GetProjInsp(pId: $pId, cId: $cId) {
      inspDate
      inspCompleted
      inspDrawAmt
      inspId
    }
  }
`;
