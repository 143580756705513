import { graphQLFetch } from "../../../utils";
import { CREATE_INSPECTION } from "../../queries";

export const createInspections =
  ({ input, pId, cId }, callback) =>
  async (dispatch, getState) => {
    delete input["inspId"];
    const variables = {
      input: { ...input, pId, cId },
    };
    const data = await graphQLFetch(
      CREATE_INSPECTION,
      variables,
      "mutation",
      getState
    );
    const { PutInsp } = data || {};
    callback(PutInsp);
  };
