// @ts-nocheck
import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

import { CustomModalBody, ActionButtons } from "./DeleteProject.styles";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import { Color } from "../../styles/Colors";

const DeleteProject: React.FC<{
  setShow: (value: boolean) => void;
  projectId?: string;
  companyId?: string;
  onDelete: () => void;
  isLoading: boolean;
}> = ({ setShow, projectId = "", onDelete, isLoading }) => {
  const onCancelHandler = () => {
    setShow(false);
  };

  const onDeleteHandler = () => {
    onDelete();
  };

  return (
    <Modal
      open
      onClose={() => {
        onCancelHandler();
      }}
      header={"Delete Project?"}
    >
      <CustomModalBody>
        Are you sure, you want to delete this project? Deleting this project
        will delete all its budget, unit and inspection data related
        information.
        <ActionButtons>
          <Button
            type="button"
            className={"btn-default"}
            onClick={onCancelHandler}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            className={"btn-primary"}
            isLoading={isLoading}
            onClick={onDeleteHandler}
          >
            {isLoading ? (
              <ClipLoader
                color={Color.white}
                size={15}
                cssOverride={{
                  marginLeft: "17px",
                  marginRight: "17px",
                  marginTop: "2px",
                  marginBottom: "2px",
                }}
              />
            ) : (
              "Delete"
            )}
          </Button>
        </ActionButtons>
      </CustomModalBody>
    </Modal>
  );
};

export default DeleteProject;
