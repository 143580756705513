import { graphQLFetch } from "../../../utils";
import { DELETE_UNIT } from "../../queries";

export const deleteUnit =
  ({ unitId, pId, cId }, callback) =>
  async (dispatch, getState) => {
    const variables = {
      unitId,
      pId,
      cId,
    };

    const data = await graphQLFetch(
      DELETE_UNIT,
      variables,
      "mutation",
      getState
    );
    const { DeleteUnit } = data || {};
    callback(DeleteUnit);
  };
