import { graphQLFetch } from "../../../utils";
import { setBudgetItems } from "./setBudgetItems";
import { CREATE_BUDGET_ITEMS } from "../../queries";

export const createBudgetItem =
({ items }, callback) => async (dispatch, getState) => { 
    const variables = {
      input: items,
    };
    const data = await graphQLFetch(
      CREATE_BUDGET_ITEMS,
      variables,
      "mutation",
      getState
    );
    const { CreateBudgetItems } = data || {};
    callback(CreateBudgetItems);

    dispatch(setBudgetItems(CreateBudgetItems || []));
  };
