import { gql } from "@apollo/client";

export const GET_BUDGET_ITEMS = gql`
  query GetBudgetItems($pId: ID!, $cId: ID!) {
    GetBudgetItems(pId: $pId, cId: $cId) {
      amt
      bdgtCategory
      bdgtDesc
      updatedOn
      bdgtLnItmId
      bdgt_line_item_version
    }
  }
`;
