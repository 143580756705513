import { gql } from "@apollo/client";

export const DELETE_UNIT = gql`
  mutation deleteUnit($unitId: ID!, $pId: ID!, $cId: ID!) {
    DeleteUnit(unitId: $unitId, pId: $pId, cId: $cId) {
      message
      success
      result
    }
  }
`;
