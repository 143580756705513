export const unit = {
  "Unit Id": {
    name: "Unit Id",
    value: "",
    required: false,
    type: "text",
    maxLength: 255,
    mappedTo: "unitId",
    fieldType: "text",
    errorMessage: "",
    dataType: "alphanumeric",
  },
  "Unit Name": {
    name: "Unit Name",
    value: "",
    required: true,
    type: "text",
    maxLength: 255,
    mappedTo: "unitName",
    fieldType: "text",
    errorMessage: "",
    dataType: "alphanumericWithSpecialChars",
    regexPattern: /^[^~]*$/,
  },
  "Number of stories": {
    name: "Number of stories",
    value: "",
    required: true,
    type: "number",
    maxLength: 3,
    mappedTo: "stories",
    fieldType: "text",
    errorMessage: "",
  },
  "Square Feet": {
    name: "Square Feet",
    value: "",
    required: true,
    type: "number",
    maxLength: 5,
    mappedTo: "sqFt",
    fieldType: "text",
    errorMessage: "",
  },
  "Number of Bedrooms": {
    name: "Number of Bedrooms",
    value: "",
    required: true,
    type: "text",
    maxLength: 4,
    mappedTo: "beds",
    fieldType: "text",
    errorMessage: "",
    regexPattern: /^[0-9]+$/,
  },
  "Number of Bathrooms": {
    name: "Number of Bathrooms",
    value: "",
    required: true,
    type: "text",
    maxLength: 4,
    mappedTo: "baths",
    fieldType: "text",
    step: "0.01",
    max: "9999",
    errorMessage: "",
    regexPattern: /^[0-9]+$/,
  },
  "Garage Stalls": {
    name: "Garage Stalls",
    value: "",
    required: false,
    type: "text",
    maxLength: 3,
    mappedTo: "gar",
    fieldType: "text",
    regexPattern: /^[0-9]+$/,
  },
  "Appraised Value": {
    name: "Appraised Value",
    value: "",
    required: false,
    type: "number",
    maxLength: 11,
    mappedTo: "apprVal",
    step: "0.01",
    max: "9999",
    fieldType: "currency",
    isDollorIcon: true,
  },
  "Internal Value": {
    name: "Internal Value",
    value: "",
    required: false,
    type: "number",
    maxLength: 11,
    mappedTo: "intVal",
    step: "0.01",
    max: "9999",
    fieldType: "currency",
    isDollorIcon: true,
  },
  "Listing Date": {
    name: "Listing Date",
    value: "",
    required: false,
    type: "date",
    mappedTo: "mlsListingDt",
    maxLength: 10,
    fieldType: "date",
  },
  "Pending Date": {
    name: "Pending Date",
    value: "",
    required: false,
    type: "date",
    maxLength: 10,
    mappedTo: "mlsPendingDt",
    fieldType: "date",
  },
  "Closing Date": {
    name: "Closing Date",
    value: "",
    required: false,
    maxLength: 10,
    type: "date",
    mappedTo: "closingDt",
    fieldType: "date",
  },
};
