import { graphQLFetch } from "../../../utils";
import { UPDATE_INSPECTIONS } from "../../queries";

export const updateInspection =
  ({ input, pId, cId }, callback) =>
  async (dispatch, getState) => {
    const variables = {
      input: { ...input, pId, cId },
    };
    const data = await graphQLFetch(
      UPDATE_INSPECTIONS,
      variables,
      "mutation",
      getState
    );
    const { UpdateInsp } = data || {};
    callback(UpdateInsp);
  };
