import { setInspections } from "../set/setInspections";
import { graphQLFetch } from "../../../utils";
import { GET_INSPECTIONS } from "../../queries";

export const getInspections =
  ({ pId, cId }, callback) =>
  async (dispatch, getState) => {
    const data = await graphQLFetch(
      GET_INSPECTIONS,
      { pId: pId, cId: cId },
      "query",
      getState
    );

    const { GetProjInsp } = data || {};
    const newItems =
      Array.isArray(GetProjInsp) && GetProjInsp.length > 0
        ? formatArr(GetProjInsp)
        : [];

    const items = {
      inspections: newItems,
    };
    callback(newItems);
    return dispatch(setInspections(items || []));
  };

export const formatArr = (items) => {
  return items.filter((item) => item);
};
