import { gql } from "@apollo/client";

export const DELETE_INSPECTION = gql`
  mutation deleteInspection($inspId: ID!, $pId: ID!, $cId: ID!) {
    DelInsp(inspId: $inspId, pId: $pId, cId: $cId) {
      message
      success
      result
    }
  }
`;
