import { graphQLFetch } from "../../../utils";
import { GET_BUDGET_CATEGORIES } from "../../queries";
import { setBudgetCategories } from "../set/setBudgetCategories";

export const getBudgetCategories = ({ pId, cId },) => async (dispatch, getState) => {
  const data = await graphQLFetch(
    GET_BUDGET_CATEGORIES,
    { pId: pId, cId: cId },
    "query",
    getState
  );
  const { GetBudgetCategories } = data || {};

  return dispatch(setBudgetCategories(GetBudgetCategories || []));
};
