export const projectFields = {
  "Company Id": {
    type: "text",
    value: "",
    mappedTo: "cId",
  },
  "Project Id": {
    type: "text",
    value: "",
    mappedTo: "pId",
    disabled: true,
  },
  "ID / Loan Number": {
    type: "text",
    value: "",
    mappedTo: "pLoanId",
    dataType: "alphanumericWithSpecialChars",
    regexPattern: /^[^~]*$/,
  },
  "Project Name": {
    type: "text",
    maxLength: 255,
    value: "",
    required: true,
    mappedTo: "pName",
    dataType: "alphanumericWithSpecialChars",
    regexPattern: /^[^~]*$/,
  },
  "Project Type": {
    type: "dropdown",
    value: null,
    required: true,
    mappedTo: "pType",
  },
  "Street Address": {
    type: "text",
    maxLength: 255,
    value: "",
    mappedTo: "addr",
    dataType: "alphanumericWithSpecialChars",
    regexPattern: /^[^~]*$/,
  },
  City: {
    type: "text",
    maxLength: 35,
    value: "",
    mappedTo: "city",
    dataType: "text",
  },
  "State": {
    type: "text",
    maxLength: 2,
    value: "",
    required: true,
    mappedTo: "st",
    dataType: "text",
  },
  "Zip Code": {
    type: "number",
    maxLength: 10,
    value: "",
    mappedTo: "zip",
  },
  County: {
    type: "text",
    maxLength: 14,
    value: "",
    required: true,
    mappedTo: "county",
    dataType: "text",
  },
  "Total Square Footage": {
    type: "number",
    maxLength: 8,
    value: "",
    required: true,
    mappedTo: "tlSqFoot",
  },
  "Number of Stories": {
    type: "text",
    maxLength: 3,
    value: "",
    required: true,
    mappedTo: "maxStories",
    regexPattern: /^[0-9]+$/,// 
  },
  "Funded Date": {
    type: "date",
    maxLength: 10,
    value: "",
    mappedTo: "fundedDate",
    showPlaceHolder: true,
    placeholder: "MM/DD/YYYY",
  },
  "Maturity Date": {
    type: "date",
    maxLength: 10,
    value: "",
    mappedTo: "matDate",
    showPlaceHolder: true,
    placeholder: "MM/DD/YYYY",
  },
  "Expected Completion Date": {
    type: "date",
    maxLength: 10,
    value: "",
    mappedTo: "expCompdate",
    showPlaceHolder: true,
    placeholder: "MM/DD/YYYY",
  },
  "Lot cost": {
    type: "number",
    maxLength: 11,
    value: "",
    mappedTo: "lotCost",
  },
  "Lot Acquisition Date": {
    type: "date",
    maxLength: 10,
    value: "",
    mappedTo: "lotAcqDate",
    showPlaceHolder: true,
    placeholder: "MM/DD/YYYY",
  },
  "Project Manager": {
    type: "text",
    maxLength: 100,
    value: "",
    mappedTo: "pm",
    dataType: "alphanumericWithSpecialChars",
    regexPattern: /^[^~]*$/,
  },
  "Site Superintendent": {
    type: "text",
    maxLength: 100,
    value: "",
    mappedTo: "ssi",
    dataType: "alphanumericWithSpecialChars",
    regexPattern: /^[^~]*$/,
  },
  Comments: {
    name: "Comments",
    type: "textarea",
    maxLength: 500,
    value: "",
    mappedTo: "comments",
    dataType: "alphanumericWithSpecialChars",
    regexPattern: /^[^~]*$/,
  },
  "Action Plan": {
    type: "textarea",
    maxLength: 500,
    value: "",
    mappedTo: "actionPlan",
    dataType: "alphanumericWithSpecialChars",
    regexPattern: /^[^~]*$/,
  },
};

const builderOptions = [
  {
    companyTypeId: 1,
    value: "Builder#Small 0-6 per year",
    label: "Builder Small (0-6/year)",
  },
  {
    companyTypeId: 2,
    value: "Builder#Medium 6-36 per year",
    label: "Builder Medium (6-36/year)",
  },
  {
    companyTypeId: 3,
    value: "Builder#Large 37+ per year",
    label: "Builder Large (37+/year)",
  },
  {
    companyTypeId: 4,
    value: "Inspections#Small 0-100 per year",
    label: "Inspections Small (0-100/year)",
  },
  {
    companyTypeId: 5,
    value: "Inspections#Medium 101-500 per year",
    label: "Inspections Medium (101-500/year)",
  },
  {
    companyTypeId: 6,

    value: "Inspections#Large 501+ per year",
    label: "Inspections Large (501+/year)",
  },
  {
    companyTypeId: 7,
    value: "Due Diligence#Small 0-500 per year",
    label: "Due Diligence Small (0-500/year)",
  },
  {
    companyTypeId: 8,
    value: "Due Diligence#Medium 501-1000 per year",
    label: "Due Diligence Medium (501-1000/year)",
  },
  {
    companyTypeId: 9,
    value: "Due Diligence#Large 1000+ per year",
    label: "Due Diligence Large (1000+/year)",
  },
  {
    companyTypeId: 10,
    value: "Lender#small 0-500 per year",
    label: "Lender Small (0-500/year)",
  },
  {
    companyTypeId: 11,
    value: "Lender#Medium 501-1000 per year",
    label: "Lender Medium (501-1000/year)",
  },
  {
    companyTypeId: 12,
    value: "Lender#Large 1001+ per year",
    label: "Lender Large (1001+/year)",
  },
];

const userTypeOptions = [
  {
    value: "account-manager",
    label: "Account Manager",
  },
  {
    value: "account-executive",
    label: "Account Executive",
  },
];