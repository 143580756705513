import { graphQLFetch } from "../../../utils";
import { DELETE_PROJECT } from "../../queries";
import { deleteProject as setDeleteProject } from "../../reducers/deleteProject";

export const deleteProject =
  ({ projectId, companyId }, callback) =>
  async (dispatch, getState) => {
    const variables = {
      pId: projectId,
      cId: companyId,
    };
    const data = await graphQLFetch(
      DELETE_PROJECT,
      variables,
      "mutation",
      getState
    );
    const { DeleteProject } = data || {};
    callback(DeleteProject);

    return dispatch(setDeleteProject(DeleteProject));
  };
