import React, { useState, useEffect } from "react";
import { UnitContainer } from "./Inspection.styles";
import { PrimeReactProvider } from "primereact/api";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import EditIcon from "../../assets/images/Common/EditIcon.png";
import DeleteIcon from "../../assets/images/Common/deleteIcon.png";
import { Button } from "../Button/Button";
import { Toolbar } from "primereact/toolbar";
import { StyledToast } from "../CreateNewUser/CreateNewUser.styles";

import {
  BudgetInfo,
  CustomDialogFooter,
  ActionButton,
} from "./Inspection.styles";

import {
  convertDatetoAWSFormat,
  convertPercentageToInt,
  convertCurrencyToNumber,
  formatInspection,
} from "../../utils";
import { TableConatiner } from "../BudgetList/BudgetList.styles";
import InspectionModal from "./InspectionModal/InspectionModal";
import Modal from "../Modal/Modal";
import {
  getInspections,
  createInspections,
  updateInspection,
  deleteInspection,
} from "../../redux";
import EmptyTable from "../UnitInformation/EmptyTable/EmptyTable";
import useReadOnlyUser from "../../hooks/useReadOnlyUser";
import { InspectionProps } from "../../types";
import { Color } from "../../styles/Colors";

const inspectionData = {
  inspectionId: {
    name: "Inspection Id",
    value: "",
    required: false,
    type: "text",
    mappedTo: "inspId",
    fieldType: "date",
    maxLength: 10,
  },
  inspectionDate: {
    name: "Inspection Date",
    value: "",
    required: true,
    type: "date",
    mappedTo: "inspDate",
    fieldType: "date",
    maxLength: 10,
  },
  complete: {
    name: "% Complete",
    value: "",
    required: true,
    type: "float",
    maxLength: 4,
    mappedTo: "inspCompleted",
    fieldType: "percentage",
  },
  drawAmount: {
    name: "Draw Amount",
    value: "",
    required: true,
    type: "number",
    maxLength: 11,
    mappedTo: "inspDrawAmt",
    fieldType: "currency",
    errorMessage: "",
  },
};

export const Inspection: React.FC<InspectionProps> = ({ pId, cId, pName }) => {
  const dispatch = useDispatch();
  const [inputValues, setInputValues] = React.useState({ ...inspectionData });
  const [products, setProducts] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [product, setProduct] = useState(null);
  const [isEdit, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const isReadonlyUser = useReadOnlyUser();

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const notifySuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const columnStyle = {
    padding: "14px 16px",
    border: "none",
    borderBottom: `1px solid ${Color.gray}`,
  };

  const getAllInspections = () => {
    setLoading(true);
    dispatch(
      getInspections({ pId, cId }, (items) => {
        if (Array.isArray(items) && items.length > 0) {
          const newItems = [...items];
          const modifiedProducts = formatInspection(newItems);
          setProducts(modifiedProducts);
        } else {
          setProducts([]);
        }
        setLoading(false);
      })
    );
  };

  useEffect(() => {
    getAllInspections();
  }, []);

  const openNew = () => {
    setInputValues({ ...inspectionData });
    setProduct(null);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setEditMode(false);
    setProductDialog(false);
    setProduct(null);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const saveProduct = () => {
    setEditMode(false);
    setBtnLoading(true);
    const obj = {};
    for (const [key, value] of Object.entries(inputValues)) {
      const attribute = value.mappedTo;
      if (value.type === "date" && value["value"]) {
        const { value: inputValue } = value || {};
        obj[attribute] = convertDatetoAWSFormat(inputValue);
      } else if (value.type === "number") {
        const { value: inputValue } = value || {};

        obj[attribute] = Number(inputValue) || 0;
      } else {
        const { value: inputValue } = value || {};

        obj[attribute] = inputValue;
      }
    }

    if (isEdit) {
      dispatch(
        updateInspection({ input: obj, pId, cId }, (res) => {
          if (res?.success) {
            notifySuccess(res?.message);
            getAllInspections();
          } else {
            notifyError(res?.message || "Not able to update Inspection");
          }
          setBtnLoading(false);
          hideDialog();
        })
      );
    } else {
      dispatch(
        createInspections({ input: obj, pId, cId }, (res) => {
          if (res?.success) {
            notifySuccess(res?.message);
            getAllInspections();
          } else {
            notifyError(res?.message || "Not able to update Inspection");
          }
          setBtnLoading(false);
          hideDialog();
        })
      );
    }
  };

  const editProduct = (product) => {
    setProduct(product);
    setProductDialog(true);
    setEditMode(true);

    setProductDialog(true);
    setEditMode(true);
    const obj = {};
    for (const [key, value] of Object.entries(inputValues)) {
      if (value.type === "date" && value["value"]) {
        const { mappedTo } = value;
        obj[key] = {
          ...value,
          value: product[mappedTo],
        };
      } else {
        const { mappedTo } = value;
        let mappedValue = product[mappedTo];
        if (value.fieldType === "currency") {
          mappedValue = convertCurrencyToNumber(product[mappedTo]);
        } else if (value.fieldType === "percentage") {
          mappedValue = convertPercentageToInt(product[mappedTo]);
        }
        obj[key] = {
          ...value,
          value: mappedValue,
        };
      }
    }
    setInputValues(obj);
  };

  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setDeleteProductDialog(true);
  };

  const deleteInsp = () => {
    setLoading(true);
    setBtnLoading(true);

    const { inspId } = product || {};

    dispatch(
      deleteInspection({ inspId, pId, cId }, (res) => {
        if (res?.success) {
          notifySuccess("Inspection deleted successfully!");
          getAllInspections();
        } else {
          notifyError("Not able to delete inspection!");
          setLoading(false);
        }
        setDeleteProductDialog(false);
        setBtnLoading(false);
      })
    );
  };

  const leftToolbarTemplate = () => {
    return (
      <BudgetInfo>
        <span>
          Total Inspections:{" "}
          {products !== null && products.length > 0 ? products.length : 0}
        </span>
      </BudgetInfo>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <Button
        type="button"
        onClick={openNew}
        hasStartIcon={true}
        className="btn-primary"
        disabled={isReadonlyUser}
      >
        Add Inspection
      </Button>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <ActionButton>
        <Button
          type="button"
          onClick={() => editProduct(rowData)}
          className="btn-default"
          disabled={isReadonlyUser}
        >
          <img src={EditIcon} alt="editIcon" />
        </Button>
        <Button
          type="button"
          onClick={() => confirmDeleteProduct(rowData)}
          className="btn-default"
          disabled={isReadonlyUser}
        >
          <img src={DeleteIcon} alt="deleteIcon" />
        </Button>
      </ActionButton>
    );
  };

  const deleteProductDialogFooter = (
    <CustomDialogFooter>
      <Button
        type="button"
        className="btn-default"
        onClick={hideDeleteProductDialog}
      >
        Cancel
      </Button>
      <Button
        type="button"
        className="btn-primary"
        onClick={deleteInsp}
        isLoading={btnLoading}
      >
        Delete
      </Button>
    </CustomDialogFooter>
  );

  return (
    <UnitContainer>
      <PrimeReactProvider>
        <div>
          <StyledToast />
          <div className="card">
            <Toolbar
              style={{ backgroundColor: "unset", border: "none" }}
              className="mb-4"
              left={leftToolbarTemplate}
              right={rightToolbarTemplate}
            ></Toolbar>
            <TableConatiner>
              <DataTable
                value={loading ? [] : products}
                showGridlines
                scrollable
                scrollHeight="400px"
                size="small"
                tableStyle={{ minWidth: "50rem", border: `1px solid ${Color.gray}` }}
                sortField={"inspDate"}
                sortOrder={1}
                loading={loading}
                emptyMessage={<EmptyTable tableName={"Inspection"} />}
              >
                <Column
                  hidden
                  style={columnStyle}
                  field="inspId"
                  header="Inspection Id"
                ></Column>
                <Column
                  style={columnStyle}
                  sortable
                  field="inspDate"
                  header="Inspection Date"
                ></Column>
                <Column
                  style={columnStyle}
                  field="inspCompleted"
                  header="% Complete"
                ></Column>
                <Column
                  style={columnStyle}
                  field="inspDrawAmt"
                  header="Draw Amount"
                ></Column>
                <Column
                  style={{ ...columnStyle, width: "160px" }}
                  field="actions"
                  header="Actions"
                  body={actionBodyTemplate}
                  exportable={false}
                  alignFrozen="right"
                  frozen
                ></Column>
              </DataTable>
            </TableConatiner>
          </div>

          {productDialog && (
            <InspectionModal
              isEdit={isEdit}
              isOpen={productDialog}
              onClose={hideDialog}
              product={product}
              saveProduct={saveProduct}
              setInputValues={setInputValues}
              inputValues={inputValues}
              pName={pName}
              btnLoading={btnLoading}
            />
          )}

          {deleteProductDialog && (
            <Modal
              open={deleteProductDialog}
              header="Delete Inspection?"
              footer={deleteProductDialogFooter}
              onClose={hideDeleteProductDialog}
            >
              <p>Are you sure you want to delete?</p>
            </Modal>
          )}
        </div>
      </PrimeReactProvider>
    </UnitContainer>
  );
};

export default Inspection;
